import Text from '@basicComponents/text';
import React from 'react';
import {View, Image, ImageBackground} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@services/global.state';
import Tag from '@basicComponents/tag';
import {homeDrawerStyle} from '../home.style';
import {checkToken, goTo, toPriceStr, useResponsiveDimensions} from '@/utils';
import {useLanguageModal} from '@/components/business/language';
import {useTranslation} from 'react-i18next';
import {languagesMap} from '@/i18n';
const isCloseSports = false;

const HomeMenu = (props: {
  setMenuOpen: (bool: boolean) => void;
  unreadCount?: number;
}) => {
  const {height: screenHeight, width: screenWidth} = useResponsiveDimensions();
  const {setMenuOpen, unreadCount} = props;
  const {i18n} = useTranslation();
  const {renderModal, show} = useLanguageModal();
  return (
    <View
      style={[
        theme.fill.fillW,
        theme.flex.col,
        theme.padding.l,
        {
          height: screenHeight,
        },
      ]}>
      <View
        style={[
          theme.padding.tbl,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByRow,
        ]}>
        <Image
          style={[homeDrawerStyle.logo]}
          source={require('@assets/icons/logo-horizontal.webp')}
        />
        <View
          style={[theme.flex.flex1, theme.flex.row, theme.flex.centerByRow]}>
          <View style={[theme.flex.flex1]} />
          <NativeTouchableOpacity onPress={() => setMenuOpen(false)}>
            <Image
              style={[homeDrawerStyle.itemIcon]}
              source={require('@assets/icons/close.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('Invitation');
          }
        }}>
        <ImageBackground
          style={[
            theme.margin.tbxl,
            {
              width: screenWidth * 0.7 - 24,
              height: (screenWidth * 0.7 - 24) * (62 / 256),
            },
          ]}
          source={require('@assets/icons/home/refer-and-earn.webp')}
          resizeMode="contain">
          <View
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                paddingLeft: '40%',
                paddingTop: 8,
                gap: 2,
              },
              theme.fill.fill,
              theme.flex.centerByRow,
            ]}>
            <Text blod main>
              Refer and Earn
            </Text>
            <Text color={'#D32426'} blod size="small">
              WIN:{toPriceStr(100)}
            </Text>
          </View>
        </ImageBackground>
      </NativeTouchableOpacity>
      {[
        {
          icon: require('@assets/icons/home/lottery.webp'),
          label: 'lottery',
          navTo: 'HomeDetail',
          navParams: {detailType: 'kerala'},
        },
        {
          icon: require('@assets/icons/home/live.webp'),
          label: 'liveCasino',
          navTo: 'Live',
        },
        {
          icon: require('@assets/icons/home/casino.webp'),
          label: 'casino',
          navTo: 'Casino',
        },
        {
          icon: require('@assets/icons/home/scratch.webp'),
          label: 'scratchOff',
          navTo: 'Scratch',
        },
        {
          icon: require('@assets/icons/home/sports.webp'),
          label: 'sports',
          navTo: 'Sports',
          checkToken: true,
          navParams: {detailType: 'kerala'},
        },
        // TODO 促销活动
        {
          icon: require('@assets/icons/home/promotions.webp'),
          label: 'promotions',
          navTo: 'Promotions',
        },
      ].map((v, i) => (
        <NativeTouchableOpacity
          key={i}
          onPress={() => {
            if (v.navTo) {
              if (!v.checkToken || checkToken()) {
                if (['Scratch', 'Sports'].includes(v.navTo)) {
                  globalStore.updateAmount.next({
                    gameType: v.navTo === 'Sports' ? 'Sports' : 'ScratchOff',
                  });
                }
                if (v.navTo === 'Sports' && !isCloseSports) {
                  globalStore.openSports.next();
                } else {
                  goTo(v.navTo, v.navParams);
                }
              }
              setTimeout(() => {
                setMenuOpen(false);
              }, 16);
            } else {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('warning.unopen'),
              });
            }
          }}
          style={[
            theme.background.lightGrey,
            theme.padding.l,
            {
              marginBottom: theme.paddingSize.xxs / 2,
            },
          ]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
              source={v.icon}
            />
            <Text fontSize={theme.fontSize.m}>
              {i18n.t('home.tab.' + v.label)}
            </Text>
          </View>
        </NativeTouchableOpacity>
      ))}
      {/* 消息模块 */}
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('Notification');
          }
        }}
        style={[
          theme.background.lightGrey,
          theme.padding.l,
          theme.margin.topxxl,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/notifications.webp')}
          />
          <Text fontSize={theme.fontSize.m}>{i18n.t('me.bottom.notify')}</Text>
          <View style={theme.flex.flex1} />
          {unreadCount != null && unreadCount > 0 && (
            <Tag content={unreadCount} />
          )}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          theme.background.lightGrey,
          theme.padding.l,
          theme.margin.topxxl,
        ]}
        onPress={() => show()}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/earth.webp')}
          />
          <Text fontSize={theme.fontSize.m}>
            {languagesMap[globalStore.lang]}
          </Text>
          <View style={theme.flex.flex1} />
          <Image
            style={[homeDrawerStyle.down]}
            source={require('@assets/icons/down.webp')}
          />
        </View>
      </NativeTouchableOpacity>
      {renderModal}
    </View>
  );
};

export default HomeMenu;
