import React from 'react';
import Text from '@basicComponents/text';
import theme from '@style';
import {LazyImageBackground} from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {HomeGameCardItem} from '../../home.type';
import {getUrlParams, goTo} from '@/utils';
import {Shadow} from 'react-native-shadow-2';
import globalStore from '@/services/global.state';
import {BasicObject} from '@/types';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const HomeDiceCard = ({
  item,
  marginTop,
  cardWidth,
  imageHeight,
}: {
  item: HomeGameCardItem;
  index: number;
  marginTop?: boolean;
  cardWidth: number;
  imageHeight: number;
}) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const shadow = {
    startColor: '#0000001a',
    distance: 4,
    offset: [0, 2],
    style: [
      theme.flex.col,
      theme.flex.alignStart,
      theme.padding.lrl,
      theme.flex.center,
      theme.borderRadius.s,
      {
        borderTopWidth: 1,
        borderTopColor: '#ffffff33',
        backgroundColor: '#ffffff1a',
        height: (cardWidth * 27) / 172,
        width: '100%',
      },
    ] as BasicObject[],
  } as BasicObject;
  if (globalStore.isWeb) {
    shadow.distance = 0;
    delete shadow.offset;
    shadow.style.push({
      boxShadow: '0 2px 8px 4px #0000001a',
    });
  }
  return (
    <NativeTouchableOpacity
      style={[
        {
          width: cardWidth,
          marginTop: calcActualSize(marginTop ? theme.paddingSize.s : 0),
        },
        theme.flex.col,
        theme.borderRadius.s,
        theme.overflow.hidden,
        theme.background.white,
      ]}
      onPress={() => {
        globalStore.updateAmount.next({gameType: 'Dice'});
        const params = getUrlParams(item.gameUrl);
        params.cycle = item.clcyle;
        goTo('Dice', params);
      }}>
      <LazyImageBackground
        height={imageHeight}
        width={cardWidth}
        imageUrl={item.gamePic}
        style={[
          theme.flex.col,
          theme.flex.end,
          theme.padding.tbl,
          {
            paddingHorizontal: calcActualSize(theme.paddingSize.m),
          },
        ]}>
        <Shadow {...shadow}>
          <Text fontSize={theme.fontSize.s} blod style={[theme.font.white]}>
            {i18n.t('home.dice.play')}
          </Text>
        </Shadow>
      </LazyImageBackground>
    </NativeTouchableOpacity>
  );
};

export default HomeDiceCard;
