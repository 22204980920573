import React from 'react';
import {HomeGameCardItem} from '../../home.type';
import HomeFloorBox from '../floor/home-floor-box';
import theme from '@/style';
import HomeCarCard from './home-car-card';
import {StyleSheet, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const bgs = [
  ['#FD3', '#FFCB47'],
  ['#26BDFF', '#5DE2EA'],
  ['#FF4343', '#FFA450'],
];

const HomeCar = ({carList}: {carList: HomeGameCardItem[]}) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const cardWidth = calcActualSize(111.66);
  const imageHeight = calcActualSize(133);
  const styles = StyleSheet.create({
    wrap: {
      minHeight: imageHeight + theme.paddingSize.l,
      marginBottom: -2,
    },
  });
  return (
    <View style={[styles.wrap]}>
      <HomeFloorBox
        title={i18n.t('home.car.title')}
        list={carList}
        itemWidth={cardWidth}
        titleIcon={require('@assets/icons/home/little.webp')}
        titleIconSize={8}
        renderItem={(item, index) => {
          return (
            <HomeCarCard
              key={index}
              imageHeight={imageHeight}
              cardWidth={cardWidth}
              item={item}
              bg={bgs[index]}
            />
          );
        }}
      />
    </View>
  );
};

export default HomeCar;
