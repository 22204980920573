declare const window: {
  gtag: any;
} & Window;

export function track(evt: string, params?: any) {
  try {
    if (window.gtag) {
      window.gtag('event', evt, params);
    }
  } catch (e) {
    console.log(e);
  }
}
