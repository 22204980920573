import Text from '@basicComponents/text';
import React, {useState} from 'react';
import {View, StyleSheet, ImageBackground, Image} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {goTo, toPriceStr} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {combineLatest} from 'rxjs';
import {MessageCountInfo, postUserInfo} from '@services/global.service';
import {useTranslation} from 'react-i18next';
import {useFocusEffect} from '@react-navigation/native';
const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

const HomeHeader = (props: {
  setMenuOpen: (bool: boolean, unreadCount?: number) => void;
  version: number;
}) => {
  const {i18n} = useTranslation();
  const {setMenuOpen, version} = props;
  const [showLogin, setShowLogin] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);
  const [userName, setUserName] = React.useState(false);
  const [userAvatar, setUserAvatar] = React.useState('');
  const [amount, setAmount] = React.useState<number>(0);
  const styles = StyleSheet.create({
    bellTipIcon: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
  });
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  useFocusEffect(
    React.useCallback(() => {
      const sub = combineLatest([
        globalStore.tokenSubject,
        globalStore.globalLoading,
      ]).subscribe(([t, l]) => {
        // 没有token且没有加载时,显示login按钮
        setShowLogin(!t && !l);
        setShowUser(!!t);
        if (t) {
          postUserInfo().then(res => {
            globalStore.userInfo = res;
            setUserName(res.userName || res.userPhone);
            setUserAvatar(res.userAvatar);
          });
        }
      });

      // 以防后台充值后金额依然没有更新
      globalStore.updateAmount.next({});
      const amountSub = globalStore.amountChanged.subscribe(res => {
        if (res.current != null) {
          setAmount(res.current);
        }
      });

      const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
        setMessageCountInfo(
          _countInfo || {
            messageTotalCount: 0,
            sysMessageCount: 0,
            sysUserMessageCount: 0,
          },
        );
      });
      return () => {
        sub.unsubscribe();
        amountSub.unsubscribe();
        msgSub.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]),
  );

  return (
    <DetailNavTitle
      containerStyle={[
        theme.flex.row,
        theme.flex.centerByCol,
        {
          paddingTop: theme.paddingSize.zorro,
          paddingBottom: theme.paddingSize.zorro,
        },
      ]}
      hideServer
      leftNode={
        <NativeTouchableOpacity
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={() =>
            setMenuOpen(true, messageCountInfo?.messageTotalCount)
          }>
          <Image
            style={[theme.icon.l, theme.margin.rightxxs]}
            source={require('@assets/icons/menu.webp')}
          />
          <Text size="medium" fontFamily="fontInterBold" accent>
            {i18n.t('home.label.menu')}
          </Text>
        </NativeTouchableOpacity>
      }
      hideAmount
      rightNode={
        <View style={[theme.flex.centerByCol, theme.flex.row]}>
          {showLogin && (
            <View
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <Button
                size="small"
                type="linear-primary"
                titleBold={true}
                title={i18n.t('me.user.loginUpper')}
                onPress={() => {
                  goTo('Login');
                }}
              />
            </View>
          )}
          {showUser && true && (
            <NativeTouchableOpacity
              onPress={() => {
                goTo('Notification');
              }}
              style={[
                theme.padding.s,
                theme.position.rel,
                {
                  marginRight: -theme.paddingSize.s,
                },
              ]}>
              <Image
                style={[theme.icon.xl]}
                source={require('@assets/icons/bell.webp')}
              />
              {messageCountInfo?.messageTotalCount > 0 && (
                <ImageBackground
                  style={[theme.icon.m, styles.bellTipIcon]}
                  source={require('@components/assets/icons/tip-dot.webp')}
                />
              )}
            </NativeTouchableOpacity>
          )}
          {showUser && (
            <NativeTouchableOpacity
              onPress={() => goTo('Me')}
              style={[
                theme.flex.centerByCol,
                theme.flex.row,
                {
                  marginLeft: theme.paddingSize.xl + theme.paddingSize.m,
                },
              ]}>
              <View style={[theme.flex.col]}>
                <Text
                  accent
                  textAlign="right"
                  style={[
                    {
                      marginBottom: -theme.paddingSize.xxs / 2,
                    },
                  ]}>
                  {userName}
                </Text>
                <Text main textAlign="right" size="medium" blod>
                  {toPriceStr(amount)}
                </Text>
              </View>
              <Image
                source={userAvatar ? {uri: userAvatar} : defaultHeaderImg}
                style={[
                  theme.icon.xxl,
                  theme.margin.lefts,
                  {borderRadius: theme.iconSize.xxl / 2},
                ]}
              />
            </NativeTouchableOpacity>
          )}
        </View>
      }>
      <View style={[theme.flex.flex1]} />
    </DetailNavTitle>
  );
};

export default HomeHeader;
