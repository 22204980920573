import React from 'react';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@/style';
import {LazyImageBackground} from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {formatDate, goTo, toPriceStr} from '@/utils';
import globalStore from '@/services/global.state';
import {DigitListItem} from '../../home.type';
import CountDown from '../count-down';
import {useTranslation} from 'react-i18next';

const getTimer = (time: number, cycle: number) => {
  if (!time) {
    return 0;
  }
  const date = formatDate(time, 'yyyy-MM-dd');
  if (date === 'Invalid Date') {
    return 0;
  }
  return (
    cycle * 60 -
    Math.round(((time - new Date(date).getTime()) % (cycle * 60000)) / 1000)
  );
};

const HomeDigitCard = ({
  item,
  cardWidth,
  imageHeight,
}: {
  item: DigitListItem;
  cardWidth: number;
  imageHeight: number;
}) => {
  const {i18n} = useTranslation();
  return (
    <NativeTouchableOpacity
      style={[
        {
          width: cardWidth,
        },
        theme.flex.col,
        theme.borderRadius.s,
        theme.overflow.hidden,
        theme.background.white,
      ]}
      onPress={() => {
        if (item.drawTime <= 0 && !item.cycle) {
          globalStore.globalWaringTotal(
            i18n.t('home.tip.closed', {name: `${item.pickName}`}),
          );
          return;
        }
        globalStore.updateAmount.next({gameType: '3Digit'});
        goTo('Digit', {
          id: item.id,
          pickName: item.pickName,
          pickGameType: item.cycle ? 'quick' : 'normal',
          cycle: item.cycle,
        });
      }}>
      <LazyImageBackground
        height={imageHeight}
        width={cardWidth}
        imageUrl={item.pickBackImg}
        style={[
          theme.flex.col,
          theme.flex.alignStart,
          theme.flex.between,
          theme.padding.s,
        ]}>
        <Text
          fontSize={8}
          blod
          style={[styles.lotteryType, theme.padding.lrxxs, theme.font.white]}>
          {item.pickName}
        </Text>
        <View style={[theme.flex.col, theme.flex.alignStart]}>
          <Text fontSize={8} style={[theme.font.white, theme.margin.btmxxs]}>
            {i18n.t('home.digit.booking')}
          </Text>
          <CountDown
            cycle={item.cycle}
            remain={
              item.cycle
                ? getTimer(item.currentTimestamp, item.cycle)
                : Math.round(item.drawTime / 1000)
            }
          />
        </View>
      </LazyImageBackground>
      <View
        style={[
          theme.padding.s,
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.start,
        ]}>
        <View style={[theme.flex.row, theme.flex.alignEnd]}>
          <Text main fontSize={theme.fontSize.s} fontFamily="fontInterBold">
            {toPriceStr(item.sellAmount, {
              currency: globalStore.currency,
            })}
          </Text>
          <Text accent fontSize={9}>
            /{i18n.t('home.digit.ticket')}
          </Text>
        </View>
      </View>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  lotteryType: {
    borderRadius: 2,
    paddingVertical: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
});

export default HomeDigitCard;
