import React, {forwardRef} from 'react';
import {View, ViewProps} from 'react-native';
import HomeFloorBox from '../floor/home-floor-box';
import HomeDiceCard from './home-dice-card';
import {HomeGameCardItem} from '../../home.type';
import theme from '@style';
import {goTo} from '@/utils';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface HomeDiceProps extends ViewProps {
  diceList: HomeGameCardItem[];
}

const HomeDice = forwardRef<View, HomeDiceProps>((props, ref) => {
  const {calcActualSize, designWidth} = useScreenSize();
  const {style, diceList, ...otherProp} = props;
  // TODO 优化布局规则
  const cardWidth = calcActualSize(
    (designWidth - theme.paddingSize.l * 2 - theme.paddingSize.s) / 2,
  );
  const imageHeight = (cardWidth * 145) / 172;
  const {i18n} = useTranslation();
  return (
    <View ref={ref} {...otherProp} style={[style]}>
      <HomeFloorBox
        title={i18n.t('home.dice.title')}
        list={diceList}
        itemWidth={cardWidth}
        titleIcon={require('@assets/icons/home/little.webp')}
        titleIconSize={8}
        onPressViewAll={() => goTo('HomeDetail', {detailType: 'dicegame'})}
        renderItem={(item, index) => {
          return (
            <HomeDiceCard
              key={index}
              imageHeight={imageHeight}
              index={index}
              cardWidth={cardWidth}
              item={item}
              marginTop={index > 1}
            />
          );
        }}
      />
    </View>
  );
});

export default HomeDice;
