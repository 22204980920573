import theme from '@style';
import {homeChatStyle} from '../home.style';
import LazyImage from '@basicComponents/image';
import React, {useMemo} from 'react';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {BasicObject} from '@types';
import globalStore from '@/services/global.state';
import {toChat} from '@/common-pages/game-navigate';

const borderWidth = 3;
const HomeChat = ({img}: {img: string}) => {
  const iconShadow = useMemo(() => {
    let shadow = {} as BasicObject;
    if (globalStore.isWeb) {
      shadow = {
        ...shadow,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      };
    } else {
      shadow = {
        ...shadow,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 12,
      };
    }
    return shadow;
  }, []);

  return (
    <TouchableOpacity
      containerStyle={[
        theme.position.abs,
        theme.background.white,
        homeChatStyle.chat,
        theme.flex.center,
        iconShadow,
        {
          borderWidth,
          borderColor: theme.basicColor.primary,
        },
      ]}
      onPress={toChat}>
      <LazyImage
        width={42}
        height={42}
        radius={42}
        imageUrl={img || require('@components/assets/icons/chat.gif')}
      />
    </TouchableOpacity>
  );
};

export default HomeChat;
