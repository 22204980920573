import theme from '@/style';
import React, {forwardRef, useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import {MatkaListItem} from '../../home.type';
import HomeFloorBox from '../floor/home-floor-box';
import {getSeperateList, goTo, useResponsiveDimensions} from '@/utils';
import HomeMatkaCard from './home-matka-card';
import {useTranslation} from 'react-i18next';

interface HomeMatkaProps extends ViewProps {
  matkaList: MatkaListItem[];
}

const HomeMatka = forwardRef<View, HomeMatkaProps>((props, ref) => {
  const {width: screenWidth} = useResponsiveDimensions();
  const {i18n} = useTranslation();
  const {style, matkaList, ...otherProp} = props;
  const cardWidth =
    (screenWidth - theme.paddingSize.l * 2 - theme.paddingSize.s) / 2;
  const imageHeight = (cardWidth * 114) / 172;
  const seprateMatkaList = useMemo(() => {
    return getSeperateList(matkaList, 2);
  }, [matkaList]);

  return (
    <View
      ref={ref}
      {...otherProp}
      style={[
        {
          minHeight: imageHeight,
        },
        style,
      ]}>
      <HomeFloorBox
        title={i18n.t('home.matka.title')}
        list={seprateMatkaList}
        itemWidth={cardWidth}
        titleIcon={require('@assets/icons/home/little.webp')}
        onPressViewAll={() => goTo('HomeDetail', {detailType: 'matka'})}
        titleIconSize={8}
        renderItem={(items, index) => {
          return (
            <View
              key={index}
              style={[
                theme.flex.row,
                {
                  columnGap: theme.paddingSize.s,
                },
              ]}>
              {items.map((item, _index) => {
                return (
                  <HomeMatkaCard
                    key={index + '-' + _index}
                    imageHeight={imageHeight}
                    cardWidth={cardWidth}
                    item={item}
                  />
                );
              })}
            </View>
          );
        }}
      />
    </View>
  );
});

export default HomeMatka;
