import theme from '@/style';
import React from 'react';
import {View, Image, StyleSheet, Animated, ScrollView} from 'react-native';
import HomeFloorTitleBox from '../floor/home-floor-title-box';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {HomeGameFloorItem} from '../../home.type';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const Tabs = ({
  activeTab = 0,
  homeGames = [],
  scrollToCurrentGame = () => {},
}: {
  scrollToCurrentGame: (index: number) => void;
  homeGames: HomeGameFloorItem[];
  activeTab: number;
}) => {
  const {calcActualSize, screenWidth} = useScreenSize();
  const styles = StyleSheet.create({
    line: {
      ...theme.background.primary,
      height: calcActualSize(4),
      width: calcActualSize(20),
    },
  });
  const tabAnim = React.useRef(new Animated.Value(0)).current;
  const [position, setPosition] = React.useState<number[]>([]);
  const scrollViewRef = React.useRef<ScrollView>(null);

  const outputRange = React.useMemo(() => {
    if (homeGames.length > 0) {
      return homeGames.map((_, i) => position[i] || 0);
    }
    return [];
  }, [homeGames, position]);

  const onMeasure = (i: number, left: number) => {
    position[i] = left;
    setPosition([...position]);
  };

  const innerStyle = StyleSheet.create({
    lineContainer: {
      position: 'absolute',
      bottom: calcActualSize(9),
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  React.useEffect(() => {
    Animated.timing(tabAnim, {
      toValue: activeTab,
      duration: 120,
      useNativeDriver: true,
    }).start();
    if (scrollViewRef.current) {
      if (activeTab * 2 > homeGames.length) {
        scrollViewRef.current.scrollToEnd();
      } else {
        scrollViewRef.current.scrollTo({
          x: 0,
          y: 0,
          animated: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <View style={{height: calcActualSize(48)}}>
      <HomeFloorTitleBox
        style={[theme.fill.fill]}
        titleBgImg={require('@assets/imgs/home/floor-bg.webp')}>
        <View
          style={[
            theme.flex.row,
            theme.flex.flex1,
            {
              paddingHorizontal: calcActualSize(12),
            },
            theme.flex.centerByCol,
          ]}>
          <Image
            style={{
              width: calcActualSize(24),
              height: calcActualSize(24),
            }}
            source={require('@assets/imgs/home/ball.webp')}
          />
          <ScrollView
            horizontal
            ref={scrollViewRef}
            style={[
              theme.fill.fillH,
              {
                marginLeft: calcActualSize(theme.paddingSize.l),
              },
            ]}
            showsHorizontalScrollIndicator={false}
            // eslint-disable-next-line react-native/no-inline-styles
            contentContainerStyle={{
              width: screenWidth + calcActualSize(60),
              justifyContent: 'space-between',
              gap: calcActualSize(theme.paddingSize.xxl),
            }}>
            {homeGames.map((_, i) => (
              <NativeTouchableOpacity
                onLayout={e => {
                  const squ = 20;
                  const {x, width} = e.nativeEvent.layout;
                  const target = (width - squ) / 2 + x;
                  onMeasure(i, target);
                }}
                onPress={() => scrollToCurrentGame(i)}
                key={i}
                style={[
                  theme.flex.centerByCol,
                  {
                    paddingTop: calcActualSize(14),
                  },
                ]}>
                <Text calc size="medium" blod={activeTab === i}>
                  {_.name === 'QuickRace'
                    ? 'Car'
                    : _.uniqueKey === 'indianLottery'
                    ? 'State Lottery '
                    : _.name}
                </Text>
              </NativeTouchableOpacity>
            ))}
            {homeGames.length > 0 && outputRange.length > 0 && (
              <Animated.View
                style={[
                  innerStyle.lineContainer,
                  {
                    transform: [
                      {
                        translateX: tabAnim.interpolate({
                          inputRange: homeGames.map((i, index) => index),
                          outputRange: outputRange,
                        }),
                      },
                    ],
                  },
                ]}>
                <View style={[styles.line]} />
              </Animated.View>
            )}
          </ScrollView>
          {/* <View
            style={[theme.flex.row, theme.flex.flex1]}
            onLayout={e => {
              setItemWidth(e.nativeEvent.layout.width / gameTabs.length);
            }}>
            {homeGames.map((_, i) => (
              <NativeTouchableOpacity
                onPress={() => scrollToCurrentGame(i)}
                key={i}
                style={[theme.flex.flex1, theme.flex.center]}>
                <Text size="medium" blod={activeTab === i}>
                  {_.name === 'QuickRace' ? 'Car' : _.name}
                </Text>
              </NativeTouchableOpacity>
            ))}
          </View> */}
        </View>
      </HomeFloorTitleBox>
    </View>
  );
};

export default Tabs;
