declare const window: {
  Android: any;
} & Window;

export const inApp = window?.navigator?.userAgent?.indexOf('Pandora') > -1;

export function getAdjustAttributionFromAPP() {
  const res = window.Android.getAdjustAttribution();
  if (res) {
    try {
      return JSON.parse(res);
    } catch (error) {
      return null;
    }
  }
  return res;
}
