import React from 'react';
import {View} from 'react-native';
import HomeDice from './components/dice/home-dice';
import {
  DigitListItem,
  KeralaListItem,
  MatkaListItem,
  HomeGameFloorItem,
} from './home.type';
import HomeDigits from './components/digit/home-digits';
import HomeColor from './components/home-color';
import HomeKerala from './components/kerala/home-kerala';
import HomeMatka from './components/matka/home-matka';
import HomeCar from './components/car/home-car';
import HomeLottery from './components/lottery/home-lottery';

export interface HomeTabListContentProps {
  homeGames: HomeGameFloorItem[];
  digitList: DigitListItem[];
  keralaList: KeralaListItem[];
  matkaList: MatkaListItem[];
  onMeasure: (index: number, anchor: number) => void;
}

const HomeTabListContent = ({
  homeGames = [],
  onMeasure = () => {},
  digitList = [],
  keralaList = [],
  matkaList = [],
}: HomeTabListContentProps) => {
  // const dataContent = [
  //   {
  //     name: 'Dice',
  //     content: <HomeDice diceList={diceList.slice(0, 2)} />,
  //   },
  //   {
  //     name: 'Color',
  //     content: <HomeColor />,
  //   },
  //   {
  //     name: '3Digits',
  //     content: <HomeDigits digitList={digitList} />,
  //   },
  //   {
  //     name: 'Car',
  //     content: <HomeCar carList={carList} />,
  //   },
  //   {
  //     name: 'Kerala',
  //     content: <HomeKerala keralaList={keralaList} />,
  //   },
  //   {
  //     name: 'Matka',
  //     content: <HomeMatka matkaList={matkaList} />,
  //   },
  // ];

  return (
    <View>
      {homeGames.map((_, i) => (
        <View
          key={i}
          onLayout={e => {
            onMeasure(i, e.nativeEvent.layout.height);
          }}>
          {_.uniqueKey === 'dice' && (
            <HomeDice diceList={_.gamesList?.slice(0, 2)} />
          )}
          {_.uniqueKey === 'color' && <HomeColor />}
          {_.uniqueKey === '3Digits' && <HomeDigits digitList={digitList} />}
          {_.uniqueKey === 'quickRace' && <HomeCar carList={_.gamesList} />}
          {_.uniqueKey === 'kerala' && <HomeKerala keralaList={keralaList} />}
          {_.uniqueKey === 'matka' && <HomeMatka matkaList={matkaList} />}
          {_.uniqueKey === 'indianLottery' && (
            <HomeLottery
              lotteryList={_.gamesList}
              serverTime={_.currentTimestamp}
            />
          )}
        </View>
      ))}
    </View>
  );
};

export default HomeTabListContent;
