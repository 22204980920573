import Text from '@basicComponents/text';
import theme from '@/style';
import React, {Component} from 'react';
import {
  ImageURISource,
  FlatList,
  View,
  NativeSyntheticEvent,
  NativeScrollEvent,
  LayoutChangeEvent,
  Image,
  Dimensions,
} from 'react-native';
import HomeFloorTitleBox from './home-floor-title-box';

interface HomeFloorBoxProps<listItemT> {
  /** 数据列表 */
  list: listItemT[];
  /** 渲染item,会返回数据列表的项,以及index */
  renderItem: (item: listItemT, index: number) => React.ReactElement;
  title: string;
  /** 标题背景图 */
  titleBgImg?: ImageURISource;
  titleIcon: ImageURISource;
  titleIconSize?: number;
  /** 每个元素的宽度,这个加上偏移量为每次拖动的距离 */
  itemWidth: number;
  /** 偏移量(中间padding的距离,默认theme.paddingSize.l) */
  offsetSize?: number;
  /** 当点击viewAll时触发的方法,如果不传,不会显示viewAll的按钮 */
  onPressViewAll?: () => void;
}

class HomeFloorBox<listItemT> extends Component<
  HomeFloorBoxProps<listItemT>,
  {
    leftDisabled: boolean;
    rightDisabled: boolean;
    currentIndex: number;
    lastOffset: number;
    /** 滚动区域一屏的宽度 */
    listLayoutWidth: number;
    /** 偏移量(中间padding的距离,默认theme.paddingSize.s) */
    offsetSize: number;
  }
> {
  constructor(props: HomeFloorBoxProps<listItemT>) {
    super(props);
    this.state = {
      leftDisabled: true,
      rightDisabled: false,
      currentIndex: 0,
      lastOffset: 0,
      listLayoutWidth: 0,
      offsetSize: props.offsetSize || theme.paddingSize.s,
    };
  }
  flatListRef = React.createRef<FlatList>();
  handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {contentOffset, layoutMeasurement, contentSize} = event.nativeEvent;
    this.setState(() => ({
      leftDisabled: contentOffset.x === 0,
      rightDisabled:
        Math.floor(contentOffset.x + layoutMeasurement.width) >=
        Math.floor(contentSize.width),
      lastOffset: contentOffset.x,
    }));
  };
  layoutList = (event: LayoutChangeEvent) => {
    event.persist && event.persist();
    this.setState(() => ({
      listLayoutWidth: event.nativeEvent.layout.width,
    }));
  };
  scrollListTo = (type: 1 | -1) => {
    this.scrollListToOffsetX(
      this.state.lastOffset +
        (this.state.listLayoutWidth + this.state.offsetSize) * type,
    );
  };
  scrollListToOffsetX = (num: number) => {
    this.flatListRef.current!.scrollToOffset({
      animated: true,
      offset: Math.round(num),
    });
  };
  caculate = (size: number) => {
    const {width} = Dimensions.get('window');
    const maxWidth = 500;
    const responsiveWidth = width > maxWidth ? maxWidth : width;
    const designWidth = 375;
    return (size * responsiveWidth) / designWidth;
  };
  render() {
    const {list, renderItem, titleIcon, title, titleIconSize} = this.props;
    return (
      <View style={[theme.fill.fillW, theme.flex.col]}>
        <HomeFloorTitleBox
          style={[theme.fill.fillW]}
          titleBgImg={this.props.titleBgImg}
          linear>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              {
                height: this.caculate(32),
                // paddingVertical: this.caculate(theme.paddingSize.s),
                paddingHorizontal: this.caculate(theme.paddingSize.l),
              },
            ]}>
            <Image
              style={[
                titleIconSize
                  ? {
                      width: this.caculate(titleIconSize),
                      height: this.caculate(titleIconSize),
                      marginRight: this.caculate(theme.paddingSize.s),
                    }
                  : {
                      width: this.caculate(theme.iconSize.l),
                      height: this.caculate(theme.iconSize.l),
                      marginRight: this.caculate(theme.paddingSize.s),
                    },
              ]}
              source={titleIcon}
            />
            <Text calc size="medium" main fontFamily="fontInterBold">
              {title}
            </Text>
          </View>
        </HomeFloorTitleBox>
        <View
          style={[
            theme.flex.row,
            theme.flex.wrap,
            {
              gap: this.caculate(8),
              marginHorizontal: this.caculate(12),
              marginBottom: this.caculate(theme.paddingSize.l),
            },
          ]}>
          {list?.map((item, index) => renderItem(item, index))}
        </View>
      </View>
    );
  }
}

export default HomeFloorBox;
