import React from 'react';
import {HomeGameCardItem} from '../../home.type';
import HomeFloorBox from '../floor/home-floor-box';
import theme from '@/style';
import {StyleSheet, View, Image} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {SafeAny} from '@/types';
import {useToast} from '@/components/basic/modal';
import {goTo, getUrlParams, toPriceStr} from '@/utils';
import LinearGradient from '@/components/basic/linear-gradient';
import LazyImage from '@/components/basic/image';
import Price from '@/components/business/price';
import Text from '@/components/basic/text';
import {formatDate} from '@/utils';
import {Svg, Path} from 'react-native-svg';

const colors: [string, string][] = [
  ['#2ABBFB', '#0095D6'],
  ['#B9A0FF', '#6F3CFF'],
  ['#FF7050', '#E53C18'],
  ['#FFC738', '#FF8A00'],
  ['#FFAF50', '#FF6B00'],
  ['#FF7A2E', '#FF3D00'],
  ['#A35B33', '#6A3C20'],
  ['#3ABD47', '#0D8719'],
  ['#FF97C9', '#D61471'],
  ['#448BF4', '#273FBA'],
  ['#BB5EF1', '#8122B8'],
  ['#28B299', '#0D7764'],
  ['#46597D', '#323552'],
  ['#629B29', '#3A730C'],
  ['#CD9A4C', '#BA713C'],
  ['#175EA0', '#2A27BA'],
  ['#D95169', '#B82273'],
  ['#2DAE70', '#13695A'],
];

const HomeLottery = ({
  lotteryList,
  serverTime,
}: {
  lotteryList: HomeGameCardItem[];
  serverTime?: number;
}) => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const cardWidth = calcActualSize(111.66);
  const imageHeight = calcActualSize(103);
  const styles = StyleSheet.create({
    wrap: {
      minHeight: imageHeight + theme.paddingSize.l,
      marginBottom: -2,
    },
    mask: {
      backgroundColor: 'rgba(0, 0, 0, 0.50)',
      zIndex: 10,
    },
    position: {
      top: 0,
      left: calcActualSize(-12),
      width: calcActualSize(92),
      height: calcActualSize(92),
    },
    shadowBox: {
      width: calcActualSize(92),
      height: calcActualSize(92),
    },
  });
  const config: {[key: string]: SafeAny} = {
    width: calcActualSize(111.66),
    height: calcActualSize(139),
  };
  const renderClose = (
    <View
      style={[
        theme.fill.fill,
        theme.position.abs,
        theme.flex.flex,
        theme.flex.center,
        styles.mask,
      ]}>
      <Image
        source={require('@assets/imgs/close.webp')}
        style={[
          {
            width: calcActualSize(88),
            height: calcActualSize(88),
          },
        ]}
      />
    </View>
  );

  const {renderModal, show} = useToast();

  const getStrArraySecend = (arr: string[]) => {
    if (arr.length < 2) {
      return '*';
    }
    return arr[1];
  };
  const getIndianLotteryData = (v: HomeGameCardItem) => {
    const price = parseFloat(
      getStrArraySecend((v.gameUrl || '').split('wonAmount=')),
    );
    const time =
      parseFloat(getStrArraySecend((v.otherParams || '').split('stopTime='))) *
      1000;
    const cycle = parseFloat(
      getStrArraySecend((v.otherParams || '').split('cycle=')),
    );
    return {
      price,
      time: isNaN(time) ? Number.MAX_SAFE_INTEGER - cycle : time,
      cycle,
    };
  };
  const jumpGame = (v: HomeGameCardItem) => {
    const lottoInfo = getUrlParams(v.gameUrl) as {
      lotteryId: string;
      wonAmount: `${number}`;
      gameType?: string;
    };
    const otherInfo = getUrlParams(v.otherParams) as {
      roundNo: string;
      stopTime: `${number}`;
      cycle?: string;
    };
    const isQuick = lottoInfo.gameType === 'quick';
    const isClosed = parseInt(otherInfo.stopTime, 10) * 1000 < serverTime!;
    if (!isQuick && isClosed) {
      show({
        type: 'warning',
        message: `${v.name} is closed now, please choose other game.`,
      });
      return;
    }
    goTo('MixLottery', {
      lotteryID: lottoInfo.lotteryId,
      isQuick: lottoInfo.gameType === 'quick' ? '1' : '0',
    });
  };

  const list = React.useMemo(() => {
    const target = lotteryList.map(v => {
      const lottoInfo = getUrlParams(v.gameUrl) as {
        lotteryId: string;
        wonAmount: `${number}`;
        gameType?: string;
      };
      const otherInfo = getUrlParams(v.otherParams) as {
        roundNo: string;
        stopTime: `${number}`;
        cycle?: string;
      };
      const isQuick = lottoInfo.gameType === 'quick';
      const isClosed = parseInt(otherInfo.stopTime, 10) * 1000 < serverTime!;
      return {
        ...v,
        lotteryId: lottoInfo.lotteryId,
        sellAmount: toPriceStr(+v.gamePrice),
        winAmount: lottoInfo.wonAmount,
        isQuick,
        drawTime: parseInt(otherInfo.stopTime, 10) * 1000,
        isClosed,
        cycle: otherInfo.cycle ? +otherInfo.cycle : 0,
        pickBackImg: '',
        result: ['-', '-', '-'],
      };
    });
    const isClosed = target.filter(item => item.isClosed && !item.isQuick);
    const isQuick = target
      .filter(item => item.isQuick)
      .sort((a, b) => a.cycle - b.cycle);
    const final = isQuick
      .concat(
        target
          .filter(item => !item.isClosed && !item.isQuick)
          .sort((a, b) => {
            return a.drawTime - b.drawTime;
          }),
      )
      .concat(isClosed);

    return final;
  }, [lotteryList, serverTime]);
  return (
    <View style={[styles.wrap]}>
      <HomeFloorBox
        title={i18n.t('home.lottery.title')}
        list={list}
        itemWidth={cardWidth}
        titleIcon={require('@assets/icons/home/little.webp')}
        titleIconSize={8}
        renderItem={(v, i) => {
          return (
            <NativeTouchableOpacity
              onPress={() => jumpGame(v)}
              key={i}
              style={[
                theme.background.palegrey,
                theme.borderRadius.m,
                theme.overflow.hidden,
                theme.position.rel,
                {
                  width: config.width,
                  height: config.height,
                },
              ]}>
              {v.isClosed && !v.isQuick && renderClose}
              <LinearGradient
                style={[
                  theme.flex.flex,
                  theme.flex.col,
                  theme.flex.between,
                  theme.flex.centerByCol,
                  {
                    width: config?.wdith,
                    height: config?.height,
                    paddingTop: calcActualSize(theme.paddingSize.xxs),
                    paddingHorizontal: calcActualSize(theme.paddingSize.l),
                    paddingBottom: calcActualSize(theme.paddingSize.l),
                  },
                ]}
                start={{x: 0, y: 0}}
                end={{x: 0, y: 1}}
                colors={colors[i % 18]}>
                {i === -1 && (
                  <View
                    style={[
                      theme.position.abs,
                      {
                        top: theme.paddingSize.zorro,
                        left: theme.paddingSize.zorro,
                      },
                    ]}>
                    <Svg
                      width={calcActualSize(30)}
                      height={calcActualSize(30)}
                      viewBox="0 0 30 30"
                      fill="none">
                      <Path
                        d="M0 8C0 3.58172 3.58172 0 8 0H30L0 30V8Z"
                        fill="#E00000"
                      />
                    </Svg>
                    <Text
                      calc
                      fontSize={10}
                      fontWeight="900"
                      fontFamily="fontDin"
                      style={[
                        theme.position.abs,
                        {
                          top: calcActualSize(4),
                          left: calcActualSize(2),
                          transform: [{rotate: '-45deg'}],
                        },
                      ]}
                      color={theme.basicColor.white}>
                      HOT
                    </Text>
                  </View>
                )}
                <View
                  style={[
                    theme.position.rel,
                    theme.flex.flex,
                    theme.flex.col,
                    theme.flex.centerByCol,
                  ]}>
                  <View style={[theme.position.abs, styles.position]}>
                    <Image
                      source={require('@assets/imgs/home/mix-img-light-bg.webp')}
                      style={styles.shadowBox}
                    />
                  </View>
                  <LazyImage
                    imageUrl={
                      v.isClosed && !v.isQuick
                        ? v.otherUrl! || v.gamePic
                        : v.gamePic
                    }
                    width={calcActualSize(64)}
                    height={calcActualSize(64)}
                  />
                  <View
                    style={[
                      theme.flex.flex,
                      theme.flex.col,
                      theme.flex.centerByCol,
                      {
                        gap: calcActualSize(theme.paddingSize.xxs),
                      },
                    ]}>
                    <Price
                      price={+v.winAmount}
                      fixed={0}
                      textProps={{
                        color: theme.basicColor.white,
                        fontSize: 16,
                        fontWeight: '900',
                        style: {
                          lineHeight: calcActualSize(theme.paddingSize.xxl),
                        },
                      }}
                    />
                    <Text
                      calc
                      fontSize={9}
                      fontWeight="900"
                      fontFamily="fontDin"
                      style={[
                        {
                          lineHeight: calcActualSize(
                            (theme.paddingSize.xs / 2) * 3,
                          ),
                        },
                      ]}
                      color={theme.basicColor.white}>
                      {i18n.t('jackpot')}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    theme.flex.flex,
                    theme.flex.col,
                    theme.flex.centerByCol,
                  ]}>
                  <Text
                    calc
                    fontSize={9}
                    fontWeight="700"
                    color={theme.basicColor.white}>
                    {/* {getIndianLotteryData(v).cycle ? 'Type' : 'Next Draw'} */}
                    {v.isQuick ? 'Type' : 'Next Draw'}
                  </Text>
                  <Text
                    calc
                    fontSize={9}
                    fontWeight="700"
                    color={theme.basicColor.white}>
                    {v.isQuick
                      ? `${v.cycle} minutes`
                      : formatDate(
                          getIndianLotteryData(v).time,
                          'Mon ddsuffix HH:mm',
                        )}
                  </Text>
                </View>
              </LinearGradient>
            </NativeTouchableOpacity>
          );
        }}
      />
      {renderModal}
    </View>
  );
};

export default HomeLottery;
