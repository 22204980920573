import theme from '@/style';
import React, {forwardRef, useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import HomeFloorBox from '../floor/home-floor-box';
import HomeDigitCard from './home-digit-card';
import {DigitListItem} from '../../home.type';
import {
  getSeperateList,
  goTo,
  saveDigitType,
  useResponsiveDimensions,
} from '@/utils';
import {useTranslation} from 'react-i18next';

interface HomeDigitProps extends ViewProps {
  digitList: DigitListItem[];
}

const HomeDigits = forwardRef<View, HomeDigitProps>((props, ref) => {
  const {width: screenWidth} = useResponsiveDimensions();
  const {i18n} = useTranslation();
  const {style, digitList, ...otherProp} = props;
  const cardWidth =
    (screenWidth - theme.paddingSize.l * 2 - theme.paddingSize.s) / 2;
  const imageHeight = (cardWidth * 114) / 172;
  const seperateDigitList = useMemo(() => {
    const saveList = digitList
      .filter(item => !item.cycle)
      .map(item => {
        return {
          label: item.pickName,
          value: item.id,
        };
      });
    saveDigitType(saveList);

    return getSeperateList(digitList, 2);
  }, [digitList]);
  return (
    <View
      ref={ref}
      {...otherProp}
      style={[
        {
          minHeight:
            (imageHeight + 31) * 2 +
            50 +
            theme.paddingSize.l +
            theme.paddingSize.s,
        },
        style,
      ]}>
      <HomeFloorBox
        title={i18n.t('home.digit.title')}
        list={seperateDigitList}
        itemWidth={cardWidth}
        titleIcon={require('@assets/icons/home/little.webp')}
        titleIconSize={8}
        onPressViewAll={() => goTo('HomeDetail', {detailType: 'digit'})}
        renderItem={(items, index) => {
          return (
            <View
              key={index}
              style={[
                theme.flex.row,
                {
                  columnGap: theme.paddingSize.s,
                },
              ]}>
              {items.map((item, _index) => {
                return (
                  <HomeDigitCard
                    key={index + '-' + _index}
                    imageHeight={imageHeight}
                    cardWidth={cardWidth}
                    item={item}
                  />
                );
              })}
            </View>
          );
        }}
      />
    </View>
  );
});

export default HomeDigits;
